/* RESPONSIBLE TEAM: team-frontend-tech */
export default {
  adminProfile: /^apps\.app\.admins\.admin/,
  appStoreOrHome: /^apps\.app\.(appstore|home)/,
  companiesRoute: /^apps\.app\.(companies)/,
  companiesList: /^apps\.app\.companies\.segments/,
  companyRoute: /^apps\.app\.companies\.company/,
  educate: /^apps\.app\.educate/,
  inboundEmail: /^apps\.app\.inbound-email/,
  inbox: /^apps\.app\.inbox\.inbox/,
  inbox2: /^inbox\.workspace\./,
  inboxSearch: /^apps\.app.*\.inbox\.search/,
  conversation: /^apps\.app\.inbox\.inbox\.(conversations\.)?conversation/,
  platformRoutes: /^apps\.app\.(users|companies|feed|platform|accounts)/,
  respondReporting: /^apps\.app\.inbox\.reporting/,
  inboxSpam: /^apps\.app\.inbox\.spam/,
  inboxRequests: /^apps\.app\.inbox\.requests/,
  inboxDashboardRoute: /^apps\.app\.inbox\.dashboard/,
  inboxExport: /^apps\.app\.inbox\.reporting\.export/,
  respondGuide: /^apps\.app\.inbox\.guide/,
  respondAbout: /^apps\.app\.inbox\.about/,
  respond: /^apps\.app\.inbox/,
  userList: /^apps\.app\.users\.segments/,
  usersRoute: /^apps\.app\.(users)/,
  userRoute: /^apps\.app\.users\.user/,
  seriesRoute: /^apps\.app\.outbound\.series\.series/,
  appSettings: {
    ticketsOnboarding: /^apps\.app\.settings\.tickets-onboarding/,
    appAndTeams:
      /^apps\.app\.settings\.(general|teammates|teams|logs|office-hours|roles|blocked-users|ai-assist)/,
    inboxAutomation:
      /^apps\.app\.settings\.(workload-management|inbox-rules|teammate-workload|assignment-preferences|automatic-away-mode|teammate-presence)/,
    email:
      /^apps\.app\.settings\.(email-custom-assets-domains|email-dedicated-ips|email-forwarding|senders|domains|email-notifications|email-auto-reply|email-spam-settings|email-conversation-history-settings|strip-inbound-email-links-settings|email-signature|email-other-settings)/,
    switch: /^apps\.app\.settings\.(switch)/,
    messenger: /^apps\.app\.settings\.(messenger|appearance|identity-verification)/,
    data: /^apps\.app\.settings\.(audiences|tags|people-segments|company-segments|people-data|company-data|conversation-data|custom-object-data|ticket-data|ticket-states|qualification-settings|saved-replies|inbox-views|events|imports|delete-user|slas|data-export|migrate-from-zendesk)/,
    inbox: /^apps\.app\.settings\.teams/,
    installation: /^apps\.app\.settings\.(web|ios|android)$/,
    developers:
      /^apps\.app\.settings\.(webhooks|workflow-connector-actions|custom-authentication-tokens)/,
    intercomOnly: /^apps\.app\.settings\.logs/,
    security:
      /^apps\.app\.settings\.(security|identity-verification|attachment-settings|compliance-documents)/,
  },
  newSettings: {
    home: /^apps\.app\.settings\.index/,
    workspace:
      /^apps\.app\.settings\.workspace\.(loading|general|teammates.*|office-hours|security|brands)$/,
    subscription: /^apps\.app\.settings\.subscription\.(loading|migration|usage|billing.*)$/,
    channels:
      /^apps\.app\.settings\.channels\.(loading|messenger.*|email.*|phone.*|switch|sms.*|social-channels|all|whatsapp|slack)$/,
    helpdesk:
      /^apps\.app\.settings\.helpdesk\.(loading|teams|assignments|macros|tickets.*|sla|rules.*)$/,
    aiAutomation:
      /^apps\.app\.settings\.ai-automation\.(loading|fin-ai-agent|helpdesk-ai|automation)$/,
    apps: /^apps\.app\.settings\.app-settings\.(loading|app-store.*|custom-actions.*|legacy-webhooks|authentication.*|developer-hub)$/,
    data: /^apps\.app\.settings\.data\.(loading|people|audiences|companies|conversation-tickets|custom-objects.*|tags|segments|imports-exports.*)$/,
    helpcenter: /^apps\.app\.settings\.helpcenter\.(loading|workspace-helpcenter.*|all)$/,
    proactive:
      /^apps\.app\.settings\.proactive-support\.(loading|subscriptions.*|newsfeeds|news-labels|customization)$/,
    personal:
      /^apps\.app\.settings\.personal\.(loading|details.*|preferences|notifications|visible-to-you.*|authentication|account-access)$/,
    messenger: {
      root: /^apps\.app\.settings\.channels\.messenger/,
      web: /^apps\.app\.settings\.channels\.messenger\.web/,
      mobileSdk: /^apps\.app\.settings\.channels\.messenger\.mobile-sdk/,
      general: /^apps\.app\.settings\.channels\.messenger\.general/,
      conversations: /^apps\.app\.settings\.channels\.messenger\.conversations/,
      install: /^apps\.app\.settings\.channels\.messenger\.install/,
      security: /^apps\.app\.settings\.channels\.messenger\.security/,
    },
    billing: {
      root: /^apps\.app\.settings\.subscription\.billing/,
      currentBillingPeriodCharges:
        /^apps\.app\.settings\.subscription\.billing\.current-billing-period-charges/,
      subscriptionDetails: /^apps\.app\.settings\.subscription\.billing\.subscription-details/,
      summary: /^apps\.app\.settings\.subscription\.billing\.summary/,
      details: /^apps\.app\.settings\.subscription\.billing\.details/,
      invoices: /^apps\.app\.settings\.subscription\.billing\.invoices/,
      manageUsage: /^apps\.app\.settings\.subscription\.billing\.manage-usage/,
      manageSubscription: /^apps\.app\.settings\.subscription\.billing\.manage-subscription/,
      migration: /^apps\.app\.settings\.subscription\.billing\.migration/,
      settings: /^apps\.app\.settings\.subscription\.billing\.settings/,
      updateSubscription: /^apps\.app\.settings\.subscription\.billing\.update-subscription/,
    },
    workspaceHelpcenter: {
      root: /^apps\.app\.settings\.helpcenter\.workspace-helpcenter/,
      collections: /^apps\.app\.settings\.helpcenter\.workspace-helpcenter\.collections/,
      settings: /^apps\.app\.settings\.helpcenter\.workspace-helpcenter\.settings/,
    },
  },
  accountSettings: {
    root: /^apps\.app\.account/,
    yourAccount: /^apps\.app\.account\.general/,
    notifications: /^apps\.app\.account\.notifications/,
    inboxSettings: /^apps\.app\.account\.(conversations|inbox-wallpaper)/,
    visibility: /^apps\.app\.account\.visibility\./,
    authentication: /^apps\.app\.account\.oauth-tokens/,
    accountAccess: /^apps\.app\.account\.account_access/,
  },
  gettingStarted: /^apps\.app\.getting-started/,
  guideLibrary: /^apps\.app\.guide-library/,
  wizard: /^apps\.app\.wizard/,
  onboardingHome: /^apps\.app\.home/,
  onboardingHomeOrMessages: /^apps\.app\.(home|messages)/,
  onboardingHomeOrPlatform: /^apps\.app\.(home|users|companies|feed|platform|accounts)/,
  operatorHome: /^apps\.app\.automation$/,
  operatorReporting: /^apps\.app\.operator\.(custom|answer)-bots?\.reporting/,
  answerBot: /^apps\.app\.automation\.resolution-bot/,
  customBots: /^apps\.app\.automation\.workflows/,
  finAIAgentSetup: /^apps\.app\.fin-ai-agent\.setup/,
  finAIAgentContent: /^apps\.app\.fin-ai-agent\.content$/,
  finAIAgentContentSuggestions: /^apps\.app\.fin-ai-agent\.content-suggestions/,
  finAIAgentCustomAnswers: /^apps\.app\.fin-ai-agent\.custom-answers/,
  finAIAgentProfiles: /^apps\.app\.fin-ai-agent\.profiles/,
  finAIAgentSettings: /^apps\.app\.fin-ai-agent\.settings/,
  finAIAgentAICategories: /^apps\.app\.fin-ai-agent\.ai-categories/,
  finAIAgentPlayground: /^apps\.app\.fin-ai-agent\.playground/, // just a redirect to /testing
  finAIAgentTesting: /^apps\.app\.fin-ai-agent\.testing/,
  automation: {
    finAIAgent:
      /^apps\.app\.automation\.(resolution-bot.*|fin-ai-agent)\.(setup|guidance|playground|testing|voice-playground|custom-answers.*|content-suggestions|profiles.*|loading|behaviors.*|ai-categories|tasks.*)/,
    insights: /^apps\.app\.automation\.insights*/,
  },
  taskBots: /^apps\.app\.operator\.task-bots/,
  workflowBasics: /^apps\.app\.automation\.basics/,
  welcomeFin: /^apps\.app\.automation\.welcome-fin/,
  externalContent: /^apps\.app\.automation\.external-content/,
  finContent: /^apps\.app\.automation\.fin-content/,
  operatorSettings: /^apps\.app\.automation\.settings/,
  customOrTaskBots: /^apps\.app\.operator\.(custom|task)-bots/,
  singleCustomBot: /^apps\.app\.automation\.workflows/,
  customBotOverview: /^apps\.app\.automation\.workflows-overview/,
  solveOnboarding: /^apps\.app\.automation\.self-solve-onboarding/,
  integrationsRoute: /^apps\.app\.integrations/,
  excludedForCardlessTrialBanner:
    /(^apps\.app\.(teams-checkout|checkout|wizard|expired-subscription))|(^apps\.new)/,
  excludedForCardlessConversionModal:
    /(^apps\.app\.(teams-checkout|checkout))|(^apps\.new)|(^preview-fin)/,
  excludedFromOnboardingRoutes:
    /(^apps\.app\.(home|test-conversation|import|onboarding|teams-checkout|checkout|created|get-started|pre-tour|seatless-experience))|(^apps\.new)/,
  excludeFromTeammateRoleDataSurvey:
    /^apps\.app\.(teams-checkout|checkout|trial|seatless-experience|standalone)/,
  excludeFromProfileSurvey: /^apps\.app\.(teams-checkout|checkout|getting-started)/,
  excludedFromPrimaryNav: /^apps\.app\.(teams-checkout|checkout|developer-hub)/,
  excludedFromExpiredSubscriptionRedirect:
    /(^apps\.app\.(expired-subscription|teams-checkout|checkout|settings|account|developer-hub|intershop|billing|platform|users|conversations|feed|companies)|(^apps\.new))|(^signup.teams)/,
  expiredSubscription: /^apps\.app\.expired-subscription/,
  teamsCheckout: /^apps\.app\.teams-checkout/,
  checkout: /^apps\.app\.checkout/,
  answersRoute: /^apps\.app\.answers/,
  intershopRoute: /^apps\.app\.intershop/,
  developerHub: {
    all: /^apps\.app\.developer-hub\.*/,
    basicInfo: /^apps\.app\.developer-hub\.app-packages\.app-package\.basic-info/,
    apiVersion: /^apps\.app\.developer-hub\.app-packages\.app-package\.api-version/,
    authentication: /^apps\.app\.developer-hub\.app-packages\.app-package\.oauth/,
    canvasKit: /^apps\.app\.developer-hub\.app-packages\.app-package\.canvas-kit/,
    review: /^apps\.app\.developer-hub\.app-packages\.app-package\.review/,
    workspaces: /^apps\.app\.developer-hub\.app-packages\.app-package\.workspaces/,
    appStoreListing: /^apps\.app\.developer-hub\.app-packages\.app-package\.app-store-listing/,
    gettingStartedGuide:
      /^apps\.app\.developer-hub\.app-packages\.app-package\.getting-started-guide/,
    customerWorkspaces: /^apps\.app\.developer-hub\.app-packages\.app-package\.customer-workspaces/,
    webhooks: /^apps\.app\.developer-hub\.app-packages\.app-package\.webhooks/,
    appPartner: /^apps\.app\.developer-hub\.app-packages\.app-package\.app-partner/,
    noAppPartner: /^apps\.app\.developer-hub\.app-packages\.app-package\.review\.no-app-partner/,
    reports: {
      base: /^apps\.app\.developer-hub\.app-packages\.app-package\.reports\..*/,
      installsAndUninstalls:
        /^apps\.app\.developer-hub\.app-packages\.app-package\.reports\.installs-and-uninstalls/,
      historyOfEvents:
        /^apps\.app\.developer-hub\.app-packages\.app-package\.reports\.history-of-events/,
    },
    edit: /^apps\.app\.developer-hub\.app-packages\.app-package\.(basic-info|api-version|oauth|webhooks|review|workspaces|app-store-listing|app-partner)\.edit/,
  },
  reports: {
    all: /^apps\.app\.reports\.*/,
    customerEngagementReports: /^apps\.app\.reports\.outbound-engagement/,
    customerSupportReports: /^apps\.app\.reports\.customer-support/,
    leadGenerationReports: /^apps\.app\.reports\.lead-generation/,
    operatorReports: /^apps\.app\.reports\.operator\.(custom|resolution)-bots?/,
    conversationalInsights: /^apps\.app\.reports\.conversational-insights/,
    customReports: /^apps\.app\.reports\.custom-reports/,
  },
  articleListAll: /^apps\.app\.articles\.articles\.list\.all/,
  testConversation: /^apps\.app\.test-conversation/,
  messenger: {
    root: /^apps\.app\.messenger/,
    web: /^apps\.app\.messenger\.web/,
    mobileSdk: /^apps\.app\.messenger\.mobile-sdk/,
    settings: /^apps\.app\.messenger\.settings/,
    conversations: /^apps\.app\.messenger\.conversations/,
    conversational: /^apps\.app\.messenger\.conversational/,
  },
  standalone: /^apps\.app\.standalone/,
};
